@for $i from 2 through 5 {
  .fa-#{$i}x > .emojione {
    width: #{18 * $i}px !important;
    height:  #{18 * $i}px !important;
  }
}

.fa-ex > .emojione {
  width: 220px !important;
  height: 220px !important;

  @media screen and (max-width: 230px) {
    width: 100% !important;
    height: 100% !important;
  }
}

div.quote {
  padding-left: 8px;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  color: $white;
  display: block;
  border-left: 4px solid #ff0000;
}

pre {
  display: block;
  border-left: 2px solid;
  border-color: #ff00f2;
  padding-left: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 5px;
  background-color: #000000;
}

.bbcode_FTL {
  display: none;
}

iframe {
  width: 100%;
  height: 100%;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.bbcode-pulse-loading {
  animation: pulse 1s ease-in-out infinite;
  animation-direction: alternate;
}

@for $i from 0 through 999 {
  .fa-rotatez#{$i} {
    transform: rotateZ(#{$i}deg);
    -moz-transform: rotateZ(#{$i}deg);
    -webkit-transform: rotateZ(#{$i}deg);
    -o-transform: rotateZ(#{$i}deg);
    -ms-transform: rotateZ(#{$i}deg);
  }
}


@for $i from 0 through 1 {
  @for $j from 0 through 1 {
    @for $k from 0 through 1 {
      @for $l from 0 through 999 {
        .fa-rotate#{$i}-#{$j}-#{$k}-#{$l} {
          transform: rotate3d(#{$i},#{$j},#{$k},#{$l}deg);
          -moz-transform: rotate3d(#{$i},#{$j},#{$k},#{$l}deg);
          -webkit-transform: rotate3d(#{$i},#{$j},#{$k},#{$l}deg);
          -o-transform: rotate3d(#{$i},#{$j},#{$k},#{$l}deg);
          -ms-transform: rotate3d(#{$i},#{$j},#{$k},#{$l}deg);
        }
      }
    }
  }
}

.marquee {
  width: 100%;
  padding: 0.5em 0;
  overflow: hidden;
  margin-bottom: 0px;
  position: relative;
}

.marquee .bbcode-marq-lateral:after {
  content:"";
  white-space: nowrap;
  padding-right: 0px;
}

.marquee .bbcode-marq-lateral {
  margin: 0;
  padding-left: 100%;
  display: inline-block;
  white-space: nowrap;
  -webkit-animation-name:marquee;
  -webkit-animation-timing-function:linear;
  -webkit-animation-duration:10s;
  -webkit-animation-iteration-count:infinite;
  -moz-animation-name:marquee;
  -moz-animation-timing-function:linear;
  -moz-animation-duration:10s;
  -moz-animation-iteration-count:infinite;
  -ms-animation-name:marquee;
  -ms-animation-timing-function:linear;
  -ms-animation-duration:10s;
  -ms-animation-iteration-count:infinite;
  -o-animation-name:marquee;
  -o-animation-timing-function:linear;
  -o-animation-duration:10s;
  -o-animation-iteration-count:infinite;
  animation-name:marquee;
  animation-timing-function:linear;
  animation-duration:10s;
  animation-iteration-count:infinite;
}
@-webkit-keyframes marquee {
  from   { -webkit-transform: translate(0%);}
  99%,to { -webkit-transform: translate(-100%);}
}
@-moz-keyframes marquee {
  from   { -moz-transform: translate(0%);}
  99%,to { -moz-transform: translate(-100%);}
}
@-ms-keyframes marquee {
  from   { -ms-transform: translate(0%);}
  99%,to { -ms-transform: translate(-100%);}
}
@-o-keyframes marquee {
  from   { -o-transform: translate(0%);}
  99%,to { -o-transform: translate(-100%);}
}
@keyframes marquee {
  from   { transform: translate(0%);}
  99%,to { transform: translate(-100%);}
}

.fa-flower-gift {
  position: absolute;
  right: 30px;
  top: 70px;
}

.status__content {

  .status__content__text{

    font-family: inherit;

    h1{
      color: #ec840d;
      font-weight: bold;
      font-size: 1.6em;
      padding: 0.5em;
      display: inline-block;
      line-height: 1.3;
      background: #dbebf8;
      vertical-align: middle;
      border-radius: 25px 25px 25px 25px;
      text-align: center;
      border-bottom: solid 3px #ff0000;
    }

    h2{
      color: #ec840d;
      font-weight: bold;
      font-size: 1.5em;
      padding: 0.5em;
      display: inline-block;
      line-height: 1.3;
      background: #dbebf8;
      vertical-align: middle;
      border-radius: 25px 25px 25px 25px;
      text-align: center;
      border-bottom: solid 3px #fffb00;
    }

    h3{
      color: #ec840d;
      font-weight: bold;
      font-size: 1.4em;
      padding: 0.5em;
      display: inline-block;
      line-height: 1.3;
      background: #dbebf8;
      vertical-align: middle;
      border-radius: 25px 25px 25px 25px;
      text-align: center;
      border-bottom: solid 3px #2bff00;
    }

    h4{
      color: #ec840d;
      font-weight: bold;
      font-size: 1.3em;
      padding: 0.5em;
      display: inline-block;
      line-height: 1.3;
      background: #dbebf8;
      vertical-align: middle;
      border-radius: 25px 25px 25px 25px;
      text-align: center;
      border-bottom: solid 3px #00ffea;
    }

    h5{
      color: #ec840d;
      font-weight: bold;
      font-size: 1.2em;
      padding: 0.5em;
      display: inline-block;
      line-height: 1.3;
      background: #dbebf8;
      vertical-align: middle;
      border-radius: 25px 25px 25px 25px;
      text-align: center;
      border-bottom: solid 3px #0004ff;
    }

    h6{
      color: #ec840d;
      font-weight: bold;
      font-size: 1.1em;
      padding: 0.5em;
      display: inline-block;
      line-height: 1.3;
      background: #dbebf8;
      vertical-align: middle;
      border-radius: 25px 25px 25px 25px;
      text-align: center;
      border-bottom: solid 3px #7700ff;
    }

    em{
      font-style: italic;
    }

    strong{
      font-weight: bold;
    }

    code{
      display: block;
      border-left: 2px solid;
      border-color: #079903;
      color: $white;
      padding-left: 10px;
      margin-top: 5px;
      margin-bottom: 5px;
      margin-left: 5px;
      background-color: #000000;

      .positive{
        color: #5bda57;
      }

      .negative{
        color: #ff4949;
      }

      .rust-fanc{
        color: #ba7eff;
      }

      .ruby-func{
        color: #24a8e6;
      }

      .rust-macro{
        color: #d2ff6a;
      }

      .contents{
        color: #ff9925;
      }
    }

    pre{
      display: inline-block;
      font-family: 'Noto Sans Japanese', sans-serif;
      font-weight: 400;
    }

    p ~ blockquote {
      margin-top: -8px;
    }

    blockquote{
      padding-left: 8px;
      margin-top: 0.5em;
      margin-bottom: 0.5em;
      color: $primary-text-color;
      background-color: $ui-base-color;
      display: block;
      border-left: 4px solid $classic-highlight-color;
    }

    ul.md-contents {
      border: double 4px #21b384;
      padding: 0.5em 1em 0.5em 2.3em;
      position: relative;
    }
    ul li.md-contents {
      line-height: 1.5;
      padding: 0.2em 0;
      list-style-type: none!important;
    }
    ul li.md-contents:before {
      font-family: FontAwesome;
      content: "\f0a4";
      position: absolute;
      left : 1em;
      color: #21b384;
    }

    ol.md-contents {
      border: double 4px #ff954f;
      padding: 0.5em 1em 0.5em 1em;
      position: relative;
      list-style: inside decimal;
    }

    ol li.md-contents {
      line-height: 1.5;
      padding: 0.2em 0;
    }

    hr {
      border-width: 2px 0px 0px 0px;
      border-style: dashed;
      border-color: #ff7676;
      height: 1px;
    }

    p>a>img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    a>img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    p>a{
      color: #1FBFF9;
    }

    a{
      color: #1FBFF9;
    }

    sup{
      font-size: 75.5%;
      vertical-align: top;
      position: relative;
      top: -0.5em;
    }

    sub{
      font-size: 75.5%;
      vertical-align: bottom;
      position: relative;
      top: 0.5em;
    }

    small{
      font-size: 50.0%;
      vertical-align: bottom;
      position: relative;
    }

    table {
      margin-top: 0.5em;
      margin-bottom: 0.5em;
      color: $classic-highlight-color;
      display: block;
      width: 100%;
      overflow: auto;
      border-spacing: 0;
      border-collapse: collapse;
    }

    table tr{
      background-color: #000000;
    }

    table th, table td{
      padding: 6px 13px;
      border: 1px solid $classic-highlight-color;
    }

    table th{
      font-weight: 600;
    }

    table thead tr{
      background-color: $black;
    }

    td, th{
      padding: 0;
    }

    span.img_FTL {
      display: none;
    }

  }

}
